import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeVi from '@angular/common/locales/vi';
import { Component, HostListener, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { delay, of } from 'rxjs';
import { ResponseData } from 'src/app/core/models/interfaces/common';
import { Permission } from 'src/app/core/models/interfaces/permission';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { environment } from 'src/environments/environment';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;
  stateView: string = STATE_RENDER_APP.INIT;
  user: any;
  isLoading = true;
  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    private translator: CustomTranslateService,
  ) {}

  @HostListener('window:message', ['$event'])
  handleReceiveMessage(e: MessageEvent) {
    if (e?.data?.type === 'authorized') {
      const accessTokenData = e.data?.token?.accessToken;
      const profileData = JSON.parse(e.data?.profile);
      if (accessTokenData && profileData) {
        localStorage.setItem('accessToken', accessTokenData);
        localStorage.setItem('auth', JSON.stringify(profileData));
        this.isLoading = false;
        this.user = profileData;
        this.user.avatarURL = `${environment.BE_URL}storage/files/web/${this.user?.avatar_thumbnail_url}.webp`;
        this.onCheckPermission();
      } else {
        window.location.href = `${environment.ADMIN_HUB_URL}/login`;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }

  ngOnInit() {
    const lang = this.translator.getCurrentLanguage();
    this.translator.setLanguage(lang);
    registerLocaleData(localeVi, 'vi');
    this.checkSmallScreen();
    of(true)
      .pipe(delay(5000))
      .subscribe(() => {
        if (this.isLoading) {
          this.messageService.add({
            severity: 'warn',
            detail: this.translator.sentenceCase(
              'common.you-need-super-admin-permissions',
            ),
          });
          setTimeout(() => {
            window.location.href = `${environment.ADMIN_HUB_URL}/login`;
          }, 2000);
        }
      });
  }

  onCheckPermission() {
    this.httpClient
      .get<ResponseData<Permission[]>>(
        `${environment.BE_URL}user/admin/roles/module`,
      )
      .subscribe({
        next: (res) => {
          const permissions = res.data ?? [];
          const isHavePermission = permissions.find(
            (permission) =>
              permission.role === 'SUPER_ADMIN' ||
              (permission.role === 'ADMIN' &&
                permission.type_moderator === 'VOUCHER_MODULE_ADMIN'),
          );
          if (isHavePermission) {
            this.stateView = STATE_RENDER_APP.APP;
          } else {
            window.location.href = `${environment.ADMIN_HUB_URL}`;
          }
        },
        error: () => {
          window.location.href = `${environment.ADMIN_HUB_URL}`;
        },
      });
  }
}
