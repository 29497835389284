export enum VoucherTabEnum {
  ALL = 'ALL',
  ONGOING = 'ONGOING',
  UPCOMING = 'UPCOMING',
  EXPIRED = 'EXPIRED',
}

export enum VoucherTypeEnum {
  RESTAURANT = 'RESTAURANT',
  NEW_BUYER = 'NEW_BUYER',
  LIVESTREAM = 'LIVESTREAM',
  SHOP = 'SHOP',
  FREE_SHIP = 'FREE_SHIP',
  SOCTRIP_SHOP = 'SOCTRIP_SHOP',
}
export enum SystemVoucherTypeEnum {
  SYSTEM = 'SYSTEM',
  MODULE = 'MODULE'
}
export enum VoucherDisplaySettingEnum {
  LIVESTREAM = 'LIVESTREAM',
  ALL_PAGE = 'ALL_PAGE',
  PRIVATE = 'PRIVATE',
}

export enum VoucherPageEnum {
  CREATE = 'add',
  VIEW = 'view',
  UPDATE = 'update',
}

export enum SortEnum {
  NAME_DESC = '-name.keyword',
  NAME_ASC = 'name.keyword',

  CODE_DESC = '-voucher_code',
  CODE_ASC = 'voucher_code',

  USED_AMOUNT_DESC = '-used_amount',
  USED_AMOUNT_ASC = 'used_amount',
}

export enum VoucherDiscountTypeEnum {
  PRICE = 'PRICE',
  PERCENTAGE = 'PERCENTAGE',
  COIN = 'COIN',
}

export enum VoucherStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ApplicableEnum {
  ALL_PRODUCT = 'ALL_PRODUCT',
  SPECIFIC_PRODUCT = 'SPECIFIC_PRODUCT',
  CATEGORY = 'CATEGORY',
}
