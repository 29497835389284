<router-outlet></router-outlet>
<app-toast-message></app-toast-message>

<iframe
  style="display: none"
  id="socialLogin"
  [src]="iframeUrl"
  title="socialLogin"
  (load)="handleOnLoad()"
></iframe>
